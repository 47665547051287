import {navigateTo} from './pageNavigation'
import {
  addPopup,
  addConnectedPopup,
  open,
  close,
  isPopupOpened,
  getApplicationPopups,
  updateBehavior,
} from './popups'
import {isOperationAllowedInContext, runInContext} from '../privates/util'
import {resolveOption} from '../../../utils/utils'

function getCurrent(documentServices /*token*/) {
  return documentServices.pages.getReference(
    documentServices.pages.getFocusedPageId(),
  )
}

function setState(documentServices, appData, token, {state}) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.platform.pages.setState(state),
  )
}

function getPrimary(documentServices) {
  return documentServices.pages.getReference(
    documentServices.pages.getPrimaryPageId(),
  )
}

function add(
  documentServices,
  appData,
  token,
  {title, definition, shouldAddMenuItem, shouldNavigateToPage = true},
) {
  const pageRef = runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.pages.add(title, definition, shouldAddMenuItem),
  )
  return shouldNavigateToPage
    ? navigateTo(documentServices, appData, token, {pageRef}, pageRef)
    : pageRef
}

function getPageData(documentServices, appData, token, {pageRef}) {
  return documentServices.pages.data.get(pageRef.id)
}

function updateData(documentServices, appData, token, {pageRef, data}) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.pages.data.update(pageRef.id, data),
  )
}

function hasPassword(documentServices, appData, token, {pageRef}) {
  return documentServices.pages.permissions.hasPassword(pageRef.id)
}

function updatePagePassword(
  documentServices,
  appData,
  token,
  {pageRef, plainPassword},
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.pages.permissions.updatePassword(
      pageRef.id,
      plainPassword,
    ),
  )
}

function removePagePassword(documentServices, appData, token, {pageRef}) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.pages.permissions.removePassword(pageRef.id),
  )
}

function duplicatePagePassword(
  documentServices,
  appData,
  token,
  {destinationPageRef, sourcePageRef},
) {
  return new Promise((resolve, reject) => {
    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.pages.permissions.duplicatePagePassword(
        destinationPageRef.id,
        sourcePageRef.id,
        resolve,
        reject,
      ),
    )
  })
}

function convertPageNameToUrl(documentServices, appData, token, {title}) {
  return documentServices.pages.data.pageUriSEO.convertPageNameToUrl(title)
}

function remove(documentServices, appData, token, {pageRef}) {
  return new Promise((resolve, reject) => {
    const pageData = documentServices.pages.data.get(pageRef.id)

    if (!pageData) {
      reject(`page with id '${pageRef.id}' doesn't exist`)
      return
    }

    if (!isOperationAllowedInContext(appData, pageData.managingAppDefId)) {
      reject(
        `page with id '${pageRef.id}' can not be deleted. page is not managed by this application`,
      )
      return
    }

    const {id: currentPageId} = documentServices.pages.getCurrentPage()
    if (currentPageId === pageRef.id) {
      reject(
        `It is not allowed to delete current page (${pageRef.id}), please navigate to another page`,
      )
      return
    }

    const homepageId = documentServices.homePage.get()
    if (pageRef.id === homepageId) {
      reject(
        `It is not allowed to delete homePage (${pageRef.id}), please set a new page as homePage and try again`,
      )
      return
    }

    if (pageData.tpaApplicationId) {
      reject(`It is not allowed to delete a section page '(${pageRef.id})'`)
      return
    }

    try {
      runInContext(appData.appDefinitionId, documentServices, () =>
        documentServices.pages.remove(pageRef.id),
      )
    } catch (ex) {
      reject(ex)
      return
    }
    resolve()
  })
}

function getHomePage(documentServices) {
  const homepageId = documentServices.homePage.get()
  return documentServices.components.get.byId(homepageId)
}

function getApplicationPages(
  documentServices,
  appData,
  token,
  {appDefinitionId, includeUnmanaged} = {},
) {
  const appDefId = resolveOption(
    appData,
    {appDefinitionId},
    'appDefinitionId',
    {isRequired: includeUnmanaged},
  )
  return appDefId
    ? documentServices.pages
        .getPagesData()
        .filter(
          ({managingAppDefId, appDefinitionId}) =>
            managingAppDefId === appDefId ||
            (includeUnmanaged && appDefinitionId === appDefId),
        )
    : []
}

function getAllPagesData(documentServices) {
  return documentServices.pages.getPagesData()
}

function serialize(
  documentServices,
  appData,
  token,
  {pageRef, maintainIdentifiers = false},
) {
  return documentServices.pages.serialize(pageRef.id, maintainIdentifiers)
}

function rename(documentServices, appData, token, {pageRef, title}) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.pages.data.update(pageRef.id, {title}),
  )
}

function isDynamicPage(documentServices, appData, token, {pageRef}) {
  const routerRef = documentServices.routers.getRouterRef.byPage(pageRef)
  return Boolean(routerRef)
}

// blank implementation to avoid errors if not implemented by editor override
// since this functionality currently only exists in classic editor
function duplicateGroupsPermissions(/*documentServices, appData, token, {sourcePageRef, destinationPageRef}*/) {
  return new Promise((resolve) => {
    resolve()
  })
}

function duplicate(
  documentServices,
  appData,
  token,
  {pageId, shouldAddMenuItem, shouldDuplicatePageCode},
) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.pages.duplicate(
      pageId,
      shouldAddMenuItem,
      shouldDuplicatePageCode,
    ),
  )
}

export default {
  navigateTo,
  setState,
  getCurrent,
  getPrimary,
  getHomePage,
  add,
  getPageData,
  isDynamicPage,
  rename,
  duplicate,
  data: {
    get: getPageData,
    getAll: getAllPagesData,
    update: updateData,
  },
  pageUriSEO: {
    convertPageNameToUrl,
  },
  serialize,
  permissions: {
    hasPassword,
    updatePagePassword,
    removePagePassword,
    duplicatePagePassword,
    duplicateGroupsPermissions,
  },
  remove,
  getApplicationPages,
  popupPages: {
    add: addPopup,
    addConnected: addConnectedPopup,
    open,
    close,
    isPopupOpened,
    getApplicationPopups,
    updateBehavior,
  },
}
