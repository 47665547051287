import {
  IFRAME_WIDGET,
  IFRAME_PAGE,
  OOI_WIDGET,
  OOI_PAGE,
  BLOCKS_WIDGET,
} from './consts'
const SUPPORTED_WIDGETS = [
  IFRAME_WIDGET,
  IFRAME_PAGE,
  OOI_WIDGET,
  OOI_PAGE,
  BLOCKS_WIDGET,
]

const compDefCreators = {
  [IFRAME_WIDGET]: createIFrameComponent,
  [IFRAME_PAGE]: createIFrameComponent,
  [OOI_WIDGET]: createTPAWidgetComponent,
  [OOI_PAGE]: createTPAWidgetComponent,
  [BLOCKS_WIDGET]: createBlocksWidgetComponent,
}

function getWidgetAppComponent(documentServicesAPI, widgetPointer) {
  const appData = documentServicesAPI.tpa.app.getDataByAppDefId(
    widgetPointer.appDefinitionId,
  )

  const widgetAppComponent = appData.components.find(
    (appComponent) => appComponent.componentId === widgetPointer.widgetId,
  )

  if (!widgetAppComponent) {
    throw new Error(
      `Application "${appData.name}" is missing the widget component with ID: ${widgetPointer.widgetId}`,
    )
  }

  return widgetAppComponent
}

function getWidgetPluginType(widgetAppComponent) {
  const widgetPluginType = widgetAppComponent?.type

  if (!SUPPORTED_WIDGETS.includes(widgetPluginType)) {
    throw new Error(`Widget-plugin type is unsupported -- ${widgetPluginType}`)
  }

  return widgetPluginType
}

export async function createWidgetPluginComponentDefinition(
  documentServicesAPI,
  widgetPointer,
  applicationId,
) {
  const widgetAppComponent = getWidgetAppComponent(
    documentServicesAPI,
    widgetPointer,
  )

  const widgetPluginType = getWidgetPluginType(widgetAppComponent)

  return widgetPluginType === BLOCKS_WIDGET
    ? compDefCreators[widgetPluginType](
        documentServicesAPI,
        widgetPointer,
        applicationId,
      )
    : compDefCreators[widgetPluginType](
        documentServicesAPI,
        widgetPointer,
        applicationId,
        widgetAppComponent,
      )
}

function createIFrameComponent(
  documentServicesAPI,
  {appDefinitionId, widgetId},
  applicationId,
  widgetPluginAppComponent,
) {
  const DEFAULT_INITIAL_HEIGHT = 100

  const widgetComponent =
    documentServicesAPI.components.buildDefaultComponentStructure(
      'wysiwyg.viewer.components.tpapps.TPAWidget',
    )

  widgetComponent.data = {
    ...widgetComponent.data,
    appDefinitionId,
    applicationId: applicationId.toString(),
    widgetId,
  }

  // iFrame needs an initial height to be rendered. Later it can set its own height via Js-SDK
  const initialHeight =
    widgetPluginAppComponent?.data?.height || DEFAULT_INITIAL_HEIGHT

  widgetComponent.layouts = {
    ...widgetComponent.layouts,
    ...getResponsiveLayouts({height: initialHeight}),
  }

  return widgetComponent
}

function createTPAWidgetComponent(
  documentServicesAPI,
  {appDefinitionId, widgetId},
  applicationId,
) {
  const widgetComponent =
    documentServicesAPI.components.buildDefaultComponentStructure(
      'wysiwyg.viewer.components.tpapps.TPAWidget',
    )

  widgetComponent.data = {
    ...widgetComponent.data,
    appDefinitionId,
    applicationId: applicationId.toString(),
    widgetId,
  }

  widgetComponent.layouts = {
    ...widgetComponent.layouts,
    ...getResponsiveLayouts(),
  }

  return widgetComponent
}

async function getDefaultPreset(documentServicesAPI, widgetPointer) {
  const appDescriptor =
    await documentServicesAPI.appStudioWidgets.getAppDescriptor(
      widgetPointer.appDefinitionId,
    )

  const widgetDescriptor = Object.values(appDescriptor.widgets).find(
    (widget) => widget.devCenterWidgetId === widgetPointer.widgetId,
  )

  const [defaultPreset] = widgetDescriptor?.presets ?? []

  if (!defaultPreset) {
    return undefined
  }

  const presetId = defaultPreset.presetId.replace('#', '')

  return {
    type: 'PresetData',
    layout: presetId,
    style: presetId,
  }
}

async function createBlocksWidgetComponent(
  documentServicesAPI,
  {appDefinitionId, widgetId},
  applicationId,
) {
  const widgetComponent =
    documentServicesAPI.components.buildDefaultComponentStructure(
      'wysiwyg.viewer.components.RefComponent',
    )

  widgetComponent.data = {
    ...widgetComponent.data,
    type: 'WidgetRef',
    appDefinitionId,
    applicationId: applicationId.toString(),
    widgetId,
  }
  widgetComponent.layouts = {
    ...widgetComponent.layouts,
    ...getResponsiveLayouts(),
  }
  widgetComponent.presets = await getDefaultPreset(documentServicesAPI, {
    appDefinitionId,
    widgetId,
  })

  return widgetComponent
}

function getResponsiveLayouts(options = {}) {
  return {
    type: 'SingleLayoutData',
    componentLayout: {
      type: 'ComponentLayout',
      height: options.height
        ? {type: 'px', value: options.height}
        : {type: 'auto'},
      width: {
        type: 'auto',
      },
    },
    itemLayout: {
      type: 'GridItemLayout',
      gridArea: {
        rowStart: 1,
        columnStart: 1,
        rowEnd: 2,
        columnEnd: 2,
      },
      alignSelf: 'stretch',
      justifySelf: 'stretch',
    },
    containerLayout: {
      type: 'GridContainerLayout',
      rows: [
        {
          type: 'fr',
          value: 1,
        },
      ],
      columns: [
        {
          type: 'fr',
          value: 1,
        },
      ],
    },
  }
}

function isAppInstalled(documentServicesAPI, widgetPointer) {
  return (
    documentServicesAPI.platform.isAppActive(widgetPointer.appDefinitionId) ||
    documentServicesAPI.tpa.app.isInstalled(widgetPointer.appDefinitionId)
  )
}

export async function provisionPluginAppIfNeeded(
  documentServicesAPI,
  widgetPointer,
) {
  const isPluginAppInstalled = isAppInstalled(
    documentServicesAPI,
    widgetPointer,
  )

  if (!isPluginAppInstalled) {
    console.warn(
      'All dependant apps should be installed manually, no dependency driver is used',
    )
    await installPluginApp(widgetPointer.appDefinitionId, documentServicesAPI)
  }

  return documentServicesAPI.tpa.app.getDataByAppDefId(
    widgetPointer.appDefinitionId,
  )
}

async function installPluginApp(appDefinitionId, documentServicesAPI) {
  return new Promise((resolve) => {
    documentServicesAPI.platform.addApps([appDefinitionId], {
      [appDefinitionId]: {headlessInstallation: true},
      singleAppCallback: () => {
        resolve()
      },
    })
  })
}
