import {isExtensionContext, resolveOption} from '../../../utils/utils'

function isSiteSaved(documentServices) {
  return (
    !documentServices.generalInfo.isFirstSave() &&
    !documentServices.generalInfo.isDraft()
  )
}

function isSitePublished(documentServices) {
  return documentServices.generalInfo.isSitePublished()
}

function getLanguage(documentServices) {
  return documentServices.generalInfo.getLanguage()
}

function getRegionalLanguage(documentServices) {
  return documentServices.generalInfo.getRegionalLanguage()
}

function getSiteId(documentServices) {
  return documentServices.generalInfo.getSiteId()
}

function getUserId(documentServices) {
  const userInfo = documentServices.generalInfo.getUserInfo()
  return userInfo.userId
}

function getUserInfo(documentServices) {
  return documentServices.generalInfo.getUserInfo()
}

function getMetaSiteId(documentServices) {
  return documentServices.generalInfo.getMetaSiteId()
}

function getPublicUrl(documentServices) {
  return documentServices.generalInfo.getPublicUrl()
}

function getAppInstance(
  documentServices,
  appData,
  token,
  {appDefinitionId} = {},
) {
  const actualAppDefinitionId = resolveOption(
    appData,
    {appDefinitionId},
    'appDefinitionId',
    {
      isRequired: true,
    },
  )

  const appDefId =
    appData.appDefinitionId === 'wix-code'
      ? documentServices.wixCode.getClientSpec().appDefinitionId
      : actualAppDefinitionId
  return documentServices.tpa.app.getDataByAppDefId(appDefId).instance
}

function parseInstancePayload(instance) {
  try {
    const instancePayload = instance.split('.')[1]
    return JSON.parse(atob(instancePayload))
  } catch (e) {
    throw new Error(`Unable to parse app's instance token. Token - ${instance}`)
  }
}

function getAppInstancePayload(documentServices, appData, token) {
  const instance = getAppInstance(documentServices, appData, token)
  if (!instance) {
    return null
  }
  return parseInstancePayload(instance)
}

function getAppInstanceId(
  documentServices,
  appData,
  token,
  {appDefinitionId} = {},
) {
  const actualAppDefinitionId = resolveOption(
    appData,
    {appDefinitionId},
    'appDefinitionId',
    {isRequired: true},
  )

  const appDefId =
    appData.appDefinitionId === 'wix-code'
      ? documentServices.wixCode.getClientSpec().appDefinitionId
      : actualAppDefinitionId
  return documentServices.tpa.app.getDataByAppDefId(appDefId).instanceId
}

function isHttpsEnabled(documentServices) {
  return documentServices.generalInfo.isHttpsEnabled()
}

function getCurrency(documentServices) {
  return documentServices.generalInfo.getCurrency()
}

function getTimeZone(documentServices) {
  return documentServices.generalInfo.getTimeZone()
}

function getVendorProductId(documentServices, appData, token, options) {
  const appDefIdFromOptions = options?.appDefinitionId

  if (!appDefIdFromOptions && isExtensionContext(appData)) {
    throw new Error('options must contain appDefinitionId')
  }

  const applicationSpecData = documentServices.platform.getAppDataByAppDefId(
    appDefIdFromOptions || appData.appDefinitionId,
  )

  return applicationSpecData?.vendorProductId
}

function getRegion(documentServices) {
  return documentServices.generalInfo.getLocale()
}

function getArtifactsUrls(documentServices, appData, artifactsNames) {
  return documentServices.generalInfo.getArtifactsUrl(artifactsNames)
}

function getArtifactOverrides(documentServices) {
  return documentServices.generalInfo.getArtifactOverrides()
}

function getAppRevisionOverrides(documentServices) {
  return documentServices.generalInfo.getAppRevisionOverrides()
}

function getSiteDisplayName(documentServices) {
  return documentServices.siteDisplayName.get()
}

function getStageViewMode(documentServices) {
  return documentServices.documentMode.getComponentViewMode()
}

function getSiteMap(documentServices /*, appData, token */) {
  return documentServices.tpa.getSiteMap()
}

function getUserGEO(documentServices) {
  return documentServices.generalInfo.getGeo()
}

export default {
  getSiteId,
  getMetaSiteId,
  getPublicUrl,
  isSitePublished,
  getLanguage,
  getRegionalLanguage,
  isSiteSaved,
  isHttpsEnabled,
  getAppInstance,
  getAppInstancePayload,
  getAppInstanceId,
  getCurrency,
  getTimeZone,
  getUserId,
  getUserInfo,
  getVendorProductId,
  getRegion,
  getArtifactsUrls,
  getArtifactOverrides,
  getSiteDisplayName,
  getStageViewMode,
  getSiteMap,
  getUserGEO,
  getAppRevisionOverrides,
}
